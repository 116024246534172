<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Погода</h1>
            </div>
            <div class="default_box_content bg_white p15" v-if="preLoad">
                <div class="title">
                    <div class="temperature">
                        {{air.C}}
                    </div>
                    <div class="description">
                        {{description.full}}
                        <svg width="55" height="36" style="width: 55px;height: 36px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                            <image v-bind:xlink:href="'https://city.ooogoroda.mobi/uploads/gismeteo-icons/new/'+this.icon+'.svg'" />
                        </svg>
                    </div>
                </div>
                <div class="info_item clearfix">
                    <div class="ii info_label">По ощущению </div>
                    <div class="ii info_value">
                        <span class="value unit unit_temperature_c">{{comfort.C}}</span>
                    </div>
                </div>
                <div class="info_item clearfix">
                    <div class="ii info_label">Ветер </div>
                    <div class="ii info_value">
                        <span class="value unit unit_temperature_c">{{windspeed.m_s}} м/с, {{direction}}</span>
                    </div>
                </div>
                <div class="info_item clearfix">
                    <div class="ii info_label">Давление </div>
                    <div class="ii info_value">
                        <span class="value unit unit_temperature_c">{{pressure.mm_hg_atm}} мм рт. ст.</span>
                    </div>
                </div>
                <div class="info_item clearfix">
                    <div class="ii info_label">Влажность </div>
                    <div class="ii info_value">
                        <span class="value unit unit_temperature_c">{{humidity.percent}} %</span>
                    </div>
                </div>
                <div class="info_item clearfix">
                    <div class="ii info_label">Температура воды </div>
                    <div class="ii info_value">
                        <span class="value unit unit_temperature_c">{{water.C}}</span>
                    </div>
                </div>
                <div class="gismeteo">
                    <a href="https://www.gismeteo.ru/" target="_blank" rel="nofollow">
                        <img src="/images/gismeteo.svg" alt="Gismeteo" title="Gismeteo">
                    </a>
                </div>
            </div>
            <div v-else>
                <PreLoadBox></PreLoadBox>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-console */
import PreLoadBox from "../../Preloader/coin";
export default {
    name: "Weather",
    components: {
        PreLoadBox,
    },
    metaInfo() {
        return {
            title: `Погода в городе `,
            meta: [
                {name: 'description', itemprop: 'description', content: `Погода на сегодня, точный прогноз погоды на сегодня для населенного пункта ${this.$myCity.name}, ${this.$myCity.federal_subject}, ${this.$myCity.country_name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Погода в городе ${this.$myCity.name}`},
                {property: 'og:description', content: `Погода на сегодня, точный прогноз погоды на сегодня для населенного пункта ${this.$myCity.name}, ${this.$myCity.federal_subject}, ${this.$myCity.country_name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            item: null,
            temperature: {},
            comfort: {},
            water: {},
            air: {},
            windspeed: {},
            pressure: {},
            humidity: {},
            description: {},
            direction: {},
            icon: null,
            windDirection: ['Штиль', 'Северный', 'Северо-восточный', 'Восточный', 'Юго-восточный', 'Южный', 'Юго-западный', 'Западный', 'Северо-западный']

        };
    },
    methods: {
        api() {
            let url = this.$config.api_url + this.$route.params.city + '/Weather.get';
            this.$http.get(url).then((response) => {
                this.item = response.data.response;
                this.comfort = response.data.response.temperature.comfort;
                this.water = response.data.response.temperature.water;
                this.air = response.data.response.temperature.air;
                this.windspeed = response.data.response.wind.speed;
                this.pressure = response.data.response.pressure;
                this.humidity = response.data.response.humidity;
                this.description = response.data.response.description;
                this.direction = this.windDirection[response.data.response.wind.direction.scale_8];
                this.icon = response.data.response.icon;
                this.preLoad = 'finish';
            })
        },

    },
    mounted() {
        this.api();

    },

}
</script>
<style scoped>
@media only screen and (max-width: 450px) {
    .temperature {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .description {
        font-weight: bold;
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .temperature {
        font-weight: bold;
        font-size: 1.7rem;
    }

    .description {
        font-weight: bold;
        font-size: 1.7rem;
    }
}

@media only screen and (min-width: 768px) {
    .temperature {
        font-weight: bold;
        font-size: 2rem;
    }

    .description {
        font-weight: bold;
        font-size: 2rem;
    }
}

.info_item {
    position: relative;
    display: block;
    margin: 15px 0;
    line-height: .7em;
    border-bottom: 1px dotted rgba(119, 119, 119, .4);

}

.info_item .ii {
    position: relative;
    display: inline-block;
    width: initial;
    background-color: #fff;
}

.info_label {
    float: left;
    padding-right: 5px;
    top: 1px;
}

.info_value {
    float: right;
    padding-left: 5px;
    background: #fff;
    top: 1px;
}

.clearfix:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
}

.title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.temperature {
    font-weight: bold;
}

.description {
    font-weight: bold;
}

.gismeteo {
    width: 100%;
    padding: 20px;
}


.gismeteo img {
    height: 20px;
}
</style>